<template>
    <div class="cashout">
        <el-card>
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>我的认证资料</span>
                </div>
            </template>
            <el-form :rules="rules" :model="arraydata" label-width="120px">
                <el-row style="padding:20px 0;background-color:#f0f0f0;text-align:center;border:1px solid #d1d1d1">
                    <el-col :span="24">线上店铺信息</el-col>
                </el-row>
                <el-row style="margin-top:0.9px;text-align: center;border:1px solid #d1d1d1" align="middle">
                    <el-col :span="6" style="padding:20px 0;background-color:#fafafa">线上店铺名称</el-col>
                    <el-col :span="18" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="onlinestore">
                            <el-input v-model="arraydata.onlinestore" :placeholder="arraydata.onlinestore" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="18" v-if="!edit">
                        {{ arraydata.onlinestore }}
                    </el-col>
                </el-row>
                <el-row style="text-align: center;border:1px solid #d1d1d1" align="middle">
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">租赁范围</el-col>
                    <el-col :span="18" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="scope">
                            <el-input v-model="arraydata.scope" :placeholder="arraydata.scope" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="18" v-if="!edit">{{ arraydata.scope }}</el-col>
                </el-row>
                <el-row style="text-align: center;border:1px solid #d1d1d1" align="middle">
                    <el-col :span="6" style="padding:20px 0;background-color:#fafafa">运营联系人姓名</el-col>
                    <el-col :span="6" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="runname">
                            <el-input v-model="arraydata.runname" :placeholder="arraydata.runname" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="!edit">{{ arraydata.runname }}</el-col>
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">运营联系人电话</el-col>
                    <el-col :span="6" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="runphone">
                            <el-input v-model="arraydata.runphone" :placeholder="arraydata.runphone" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="!edit">{{ arraydata.runphone }}</el-col>
                </el-row>
                <el-row style="border:1px solid #d1d1d1;text-align: center;" align="middle">
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">邮箱</el-col>
                    <el-col :span="6" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="mail">
                            <el-input v-model="arraydata.mail" :placeholder="arraydata.mail" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="!edit">{{ arraydata.mail }}</el-col>
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">发票</el-col>
                    <el-col :span="6">普通发票</el-col>
                </el-row>

                <el-row style="padding: 20px 0;border:1px solid #d1d1d1;background-color:#f0f0f0;text-align:center;">
                    <el-col :span="24">企业信息</el-col>
                </el-row>
                <el-row style="border:1px solid #d1d1d1;text-align: center;" align="middle">
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">企业名称</el-col>
                    <el-col :span="18" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="companyname">
                            <el-input v-model="arraydata.companyname" :placeholder="arraydata.companyname" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="18" v-if="!edit">{{ arraydata.companyname }}</el-col>
                </el-row>
                <el-row style="border:1px solid #d1d1d1;text-align: center;" align="middle">
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">企业类型</el-col>
                    <el-col :span="18" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="companytype">
                            <el-radio-group v-model="arraydata.companytype">
                                <el-radio value="1">企业</el-radio>
                                <el-radio value="2">个体工商户</el-radio>>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="18" v-if="!edit">
                        <span v-if="arraydata.companytype == 1">企业</span>
                        <span v-if="arraydata.companytype == 2">个体工商户</span>
                    </el-col>
                </el-row>
                <el-row style="border:1px solid #d1d1d1;text-align: center;" align="middle">
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">企业收款支付宝账号</el-col>
                    <el-col :span="18" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="companyzfb">
                            <el-input v-model="arraydata.companyzfb" :placholder="arraydata.companyzfb" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="18" v-if="!edit">{{ arraydata.companyzfb }}</el-col>
                </el-row>

                <el-row style="border:1px solid #d1d1d1;text-align: center;" align="middle">
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">注册城市</el-col>
                    <el-col :span="18" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="registercity">
                            <el-input v-model="arraydata.registercity" :placeholder="arraydata.registercity" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="18" v-if="!edit">{{ arraydata.registercity }}</el-col>
                </el-row>
                <el-row style="border:1px solid #d1d1d1;text-align: center;" align="middle">
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">
                        法定代表人：
                    </el-col>
                    <el-col :span="18" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="legal">
                            <el-input v-model="arraydata.legal" :palceholder="arraydata.legal" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="18" v-if="!edit">
                        {{ arraydata.legal }}
                    </el-col>
                </el-row>
                <el-row style="border:1px solid #d1d1d1;text-align: center;" align="middle">
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">
                        统一社会信用代码：

                    </el-col>
                    <el-col :span="18" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="social">
                            <el-input v-model="arraydata.social" :placeholder="arraydata.social" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="18" v-if="!edit">
                        {{ arraydata.social }}
                    </el-col>
                </el-row>
                <el-row style="border:1px solid #d1d1d1;text-align: center;" align="middle">
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">
                        营业执照
                    </el-col>
                    <el-col :span="18" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="businesslicense">
                            <el-upload v-model:file-list="fileList" :action="upload_url" list-type="picture-card"
                                :on-success="uploadsuccess" :on-preview="handlePictureCardPreview"
                                 :limit="1">
                                <el-icon>
                                    <Plus />
                                </el-icon>
                            </el-upload>

                            <el-dialog v-model="dialogVisible">
                                <img w-full style="width: 100%;" :src="arraydata.businesslicense" alt="Preview Image" />
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                    <el-col :span="18" v-if="!edit">
                        <el-image style="width: 100px; height: 100px" :preview-src-list="srcList1"
                            :src="arraydata.businesslicense" fit="contain" />
                    </el-col>
                </el-row>
                <el-row style="border:1px solid #d1d1d1;text-align: center;" align="middle">
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">公司地址</el-col>
                    <el-col :span="18" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="companyaddress">
                            <el-input v-model="arraydata.companyaddress" :placeholder="arraydata.companyaddress" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="18" v-if="!edit">{{ arraydata.companyaddress }}</el-col>
                </el-row>
                <el-row style="padding: 20px 0;border:1px solid #d1d1d1;background-color:#f0f0f0;text-align:center;">
                    <el-col :span="24">个人信息</el-col>
                </el-row>
                <el-row style="border:1px solid #d1d1d1;text-align: center;" align="middle">
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">法人姓名</el-col>
                    <el-col :span="6" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="legalname">
                            <el-input v-model="arraydata.legalname" :placeholder="arraydata.legalname" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="!edit">{{ arraydata.legalname }}</el-col>
                    <el-col :span="6" style="padding:20px 0;background-color:#fafafa">法人联系方式</el-col>
                    <el-col :span="6" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="legalphone">
                            <el-input v-model="arraydata.legalphone" :placeholder="arraydata.legalphone" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="!edit">{{ arraydata.legalphone }}</el-col>
                </el-row>
                <el-row style="border:1px solid #d1d1d1;text-align: center;" align="middle">
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">
                        身份证人像面
                    </el-col>
                    <el-col :span="6" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="identitypeople">
                            <el-upload v-model:file-list="fileList1" :action="upload_url" list-type="picture-card"
                                :on-success="uploadsuccess1" :on-preview="handlePictureCardPreview1"
                                :limit="1">
                                <el-icon>
                                    <Plus />
                                </el-icon>
                            </el-upload>

                            <el-dialog v-model="dialogVisible1">
                                <img w-full style="width: 100%;" :src="arraydata.identitypeople" alt="Preview Image" />
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="!edit">
                        <el-image style="width: 100px; height: 100px" :preview-src-list="srcList2"
                            :src="arraydata.identitypeople" fit="contain" />
                    </el-col>
                    <el-col :span="6" style="padding:20px 0;background-color:#fafafa">
                        身份证国徽面
                    </el-col>
                    <el-col :span="6" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="identitynational">
                            <el-upload v-model:file-list="fileList" :action="upload_url" list-type="picture-card"
                                :on-success="uploadsuccess2" :on-preview="handlePictureCardPreview2"
                                 :limit="1">
                                <el-icon>
                                    <Plus />
                                </el-icon>
                            </el-upload>

                            <el-dialog v-model="dialogVisible2">
                                <img w-full style="width: 100%;" :src="arraydata.identitynational"
                                    alt="Preview Image" />
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="!edit">
                        <el-image style="width: 100px; height: 100px" :preview-src-list="srcList3"
                            :src="arraydata.identitynational" fit="contain" />
                    </el-col>
                </el-row>
                <el-row style="border:1px solid #d1d1d1;text-align: center;" align="middle">
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">
                        法人身份证号
                    </el-col>
                    <el-col :span="18" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="legalidentity">
                            <el-input v-model="arraydata.legalidentity" :placeholder="arraydata.legalidentity" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="18" v-if="!edit">
                        {{ arraydata.legalidentity }}
                    </el-col>
                </el-row>
                <el-row style="padding: 20px 0;border:1px solid #d1d1d1;background-color:#f0f0f0;text-align:center;">
                    <el-col :span="24">
                        线下门店信息
                    </el-col>
                </el-row>
                <el-row style="border:1px solid #d1d1d1;text-align: center;" align="middle">
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">
                        实际经营地址
                    </el-col>
                    <el-col :span="18" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="address">
                            <el-input v-model="arraydata.address" :placeholder="arraydata.address" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="18" v-if="!edit">
                        {{ arraydata.address }}
                    </el-col>
                </el-row>

                <el-row style="border:1px solid #d1d1d1;text-align: center;" align="middle">
                    <el-col :span="6" style="padding: 20px 0;background-color:#fafafa">
                        门店照片
                    </el-col>
                    <el-col :span="18" v-if="edit">
                        <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="merchantphoto">
                            <el-upload v-model:file-list="fileList3" :action="upload_url" list-type="picture-card"
                                :on-success="uploadsuccess3" :on-preview="handlePictureCardPreview3"
                                 :limit="1">
                                <el-icon>
                                    <Plus />
                                </el-icon>
                            </el-upload>

                            <el-dialog v-model="dialogVisible3">
                                <img w-full style="width: 100%;" :src="arraydata.merchantphoto" alt="Preview Image" />
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                    <el-col :span="18" v-if="!edit">
                        <el-image style="width: 100px; height: 100px" :preview-src-list="srcList4"
                            :src="arraydata.merchantphoto" fit="contain" />
                    </el-col>
                </el-row>
                <!-- <el-row style="border:1px solid #d1d1d1;text-align: center;" align="middle">
                <el-col :span="6" style="background-color:#fafafa;padding: 20px 0;">
                    门店视频
                </el-col>
                <el-col :span="18" v-if="edit">
                    <el-form-item style="margin-bottom:0px;margin-left:-120px;" prop="merchantvideo">
                        <el-input v-model="arraydata.merchantvideo"  :placeholder="arraydata.merchantvideo" />
                    </el-form-item>
                </el-col>
                <el-col :span="18" v-if="!edit">
                    {{ arraydata.merchantvideo }}
                </el-col>
            </el-row> -->
                <el-row style="border:1px solid #d1d1d1;text-align: center;" align="middle">
                    <el-col :span="6" style="background-color:#fafafa;padding: 20px 0;">
                        更新时间
                    </el-col>
                    <el-col :span="18">
                        {{ arraydata.submittime }}
                    </el-col>

                </el-row>
                <el-row style="padding:20px 0;text-align: center;" align="middle">
                    <el-col style="text-align:right;padding-right: 20px;" v-if="ismerchant != 1" :span="8">
                        <el-button @click="editclick" type="danger">修改</el-button>
                    </el-col>
                    <el-col :span="8" v-if="!edit" @click="back">
                        <el-button type="warning">返回</el-button>
                    </el-col>
                    <el-col :span="8" v-if="edit" @click="edit = false">
                        <el-button type="warning">取消</el-button>
                    </el-col>
                    <el-col style="text-align:left;padding-left: 20px;" :span="8">
                        <el-button v-if="edit" type="primary" @click="saveform">保存</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
    </div>

</template>
<script>
//import qs from 'qs'
import { ElMessage } from 'element-plus'
import Cookies from 'js-cookie'
import constant from '@/constant'
import { ref, reactive } from 'vue'
import { Plus } from '@element-plus/icons-vue'
export default {
    data() {
        return {
            srcList1: [],
            srcList2: [],
            srcList3: [],
            srcList4: [],
            fileList: [],
            fileList1: [],
            fileList2: [],
            fileList3: [],
            dialogVisible: ref(false),
            dialogVisible1: ref(false),
            dialogVisible2: ref(false),
            dialogVisible3: ref(false),
            upload_url: constant.upload_temp_img_url,
            rules: reactive({
                onlinestore: [{ required: true, message: '请输入店铺名称', trigger: 'blur' }],
                scope: [{ required: true, message: '请输入租赁范围', trigger: 'blur' }],
                runname: [{ required: true, message: '请输入运营联系人', trigger: 'blur' }],
                companyname: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
                companyzfb: [{ required: true, message: '请输入公司支付宝账户', trigger: 'blur' }],
                registercity: [{ required: true, message: '请输入注册城市', trigger: 'blur' }],
                legal: [{ required: true, message: '请输入法定代表人', trigger: 'blur' }],
                social: [{ required: true, message: '请输入统一社会信用代码', trigger: 'blur' }],
                companyaddress: [{ required: true, message: '请输入公司地址', trigger: 'blur' }],
                legalname: [{ required: true, message: '请输入法人', trigger: 'blur' }],
                legalphone: [{ required: true, message: '请输入法人联系电话', trigger: 'blur' }],
                address: [{ required: true, message: '请输入实际经营地址', trigger: 'blur' }],
                runphone: [{
                    validator: (rule, value, callback) => {
                        console.log(rule)
                        if (value == '') {
                            callback(new Error('手机号不能为空!'))
                        } else {
                            if (this.arraydata.runphone != '') {
                                if (!(/^1[3456789]\d{9}$/.test(this.arraydata.runphone))) {
                                    ElMessage({
                                        type: "error",
                                        message: "请输入正确的手机号码格式"
                                    })
                                    return false;
                                }
                            }
                        }
                    }, trigger: 'blur'
                }],
                mail: [{
                    validator: (rule, value, callback) => {
                        if (value == '') {
                            callback(new Error('邮箱不能为空!'))
                        } else {
                            if (this.arraydata.mail != '') {
                                var reg = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/;
                                if (!reg.test(this.arraydata.mail)) {
                                    ElMessage({
                                        type: "error",
                                        message: "请输入正确的邮箱格式!"
                                    })
                                    return false;
                                }
                            }
                        }
                        console.log(rule)
                    }, trigger: 'blur'
                }],
                //age: [{ validator: checkAge, trigger: 'blur' }],
            }),
            ismerchant: ref(""),
            mid: ref(""),
            edit: ref(false),
            arraydata: reactive({
                onlinestore: ref(""),
                scope: ref(""),
                runname: ref(""),
                runphone: ref(""),
                mail: ref(""),
                companyname: ref(""),
                companytype: ref(""),
                companyzfb: ref(""),
                registercity: ref(""),
                legal: ref(""),
                social: ref(""),
                businesslicense: ref(""),
                companyaddress: ref(""),
                legalname: ref(""),
                legalphone: ref(""),
                identitypeople: ref(""),
                identitynational: ref(""),
                address: ref(""),
                merchantphoto: ref(""),
                merchantvideo: ref(""),
            }),
            dis: ref("false"),
            mmid: "",
            mid2: "",
        }
    },
    components: {
        Plus
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        handlePictureCardPreview() {
            this.dialogVisible = true
        },
        uploadsuccess(file) {
            console.log(file)
            this.arraydata.businesslicense = file.url
        },
        handlePictureCardPreview1() {
            this.dialogVisible1 = true
        },
        uploadsuccess1(file) {
            console.log(file)
            this.arraydata.identitypeople = file.url
        },
        handlePictureCardPreview2() {
            this.dialogVisible2 = true
        },
        uploadsuccess2(file) {
            console.log(file)
            this.arraydata.identitynational = file.url
        },
        handlePictureCardPreview3() {
            this.dialogVisible3 = true
        },
        uploadsuccess3(file) {
            console.log(file)
            this.arraydata.merchantphoto = file.url
        },
        editclick() {
            this.edit = true
        },
        saveform() {
            console.log(this.arraydata)
            this.axios.get(constant.savepersoninfo, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    param: JSON.stringify(this.arraydata),
                }
            }).then((response) => {
                console.log(response)
                if (response.data.code == 200) {
                    ElMessage({
                        type: "success",
                        message: "修改成功"
                    })
                    this.edit = false
                } else {
                    ElMessage({
                        type: "error",
                        message: "修改失败"
                    })
                }
            })
        }
    },
    created: function () {
        this.mid2 = this.$route.query.id
        console.log(this.mid2)
        if (this.mid2 == undefined) {
            this.mid = Cookies.get("id");
            this.mmid = ""
        } else {
            this.mmid = this.mid2
            this.mid = ""
        }

        console.log(this.mid)
        this.ismerchant = Cookies.get("IsMerchant");
        console.log(this.ismerchant)
        if (this.mid) {
            console.log("mid--" + this.mid);
            this.merchantid = this.mid;
        }
        this.axios.get(constant.personinfo, {
            headers: {
                "content-type": "application/json"
            },
            params: {
                mid: this.mid,
                mmid: this.mmid
            }
        }).then((response) => {
            console.log(response.data)
            this.arraydata = response.data.result
            let v = {
                url: response.data.result.businesslicense
            }
            this.srcList1.push(response.data.result.businesslicense)
            this.fileList.push(v)
            let v1 = {
                url: response.data.result.identitypeople
            }
            this.srcList2.push(response.data.result.identitypeople);
            this.fileList1.push(v1)
            let v2 = {
                url: response.data.result.identitynational
            }
            console.log(response.data.result.identitynational)
            this.srcList3.push(response.data.result.identitynational);
            console.log(this.srcList3)
            this.fileList2.push(v2)
            let v3 = {
                url: response.data.result.merchantphoto
            }
            this.srcList4.push(response.data.result.merchantphoto)
            this.fileList3.push(v3)
        })
    }
}
</script>
<style>
.el-form-item__content {
    margin-left: 0;
}

.el-form-item .is-validating {
    margin-bottom: 0;
}
</style>